import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { fontSizes, useThemeContext } from "../../structure/theme"
import { responsiveHamburger } from "../menu/responsiveHamburger"
import Section from "../../../components/section.component"
import Phone from "../../../components/pictos/phone.component"
import PhoneCta from "../../contact/phoneCta.component"
import { firstLetterUppercase } from "../../../utils/format"
import usePhoneCostNumber from "../../contact/phoneCostNumber.hook"

const Container = styled.div`
  display: flex;
  align-items: center;
  height: 38px;
  color: white;

  ${responsiveHamburger`
    display: none;
  `}
`

const Notice = styled.span`
  font-size: ${fontSizes(`x_small`)};
`

const Number = styled.span`
  margin-right: 10px;
  font-weight: 900;
`

const Picto = styled.span`
  margin-right: 5px;
  transform: rotate(90deg);
`

export default function PostHeader ({ phoneNumber }) {
  const phoneCostNumber = usePhoneCostNumber()
  const theme = useThemeContext()

  return (
    <Section bgColor={theme.colors.grey.g800} bgWidth="full">
      <Container data-testid="postheader">
        <Picto>
          <Phone size={25} color="white" />
        </Picto>
        <Number>
          <PhoneCta>{phoneNumber}</PhoneCta>
        </Number>
        {phoneCostNumber && <Notice>{firstLetterUppercase(phoneCostNumber)}</Notice>}
      </Container>
    </Section>
  )
}

PostHeader.propTypes = {
  phoneNumber: PropTypes.string,
}
